// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { Constants } from './config/Constants';

// Initialize Firebase
const app = initializeApp(Constants.firebaseConfig);

// Initialize Firestore
const firestore = getFirestore(app);

export { firestore };
