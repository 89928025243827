import { Box, Button, FormHelperText, IconButton, InputBase, Paper, Stack, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useState } from "react"
import { BsEyeFill, BsEyeSlash } from "react-icons/bs"
import { CiLock } from "react-icons/ci"


export default ({ handleAlert, handleClose }) => {

    const [pass1, setPass1] = useState('')
    const [showPass1, setShowPass1] = useState(false)
    const [pass2, setPass2] = useState('')
    const [showPass2, setShowPass2] = useState(false)
    const [error, setError] = useState(null)
    const [btnLoading, setBtnLoading] = useState(false);
    const classes = useStyles();

    const changePass = async (e) => {
        e.preventDefault()
        if (pass1 !== pass2) {
            setError("Password does not match")
            return
        }
        setError(null)
        setBtnLoading(true)
        setTimeout(() => {
            handleAlert({ type: "success", message: "Success" })
            setBtnLoading(false)
            handleClose()
        }, 400)
    };

    return (
        <Box className={classes.container}>
            <Typography variant="h4" mb={2}>Change password</Typography>
            <Typography
                fontSize="15"
                mt={1}
                color="#a5a5a5"
                fontWeight="small"
            >
                Set a new password
            </Typography>

            <form onSubmit={changePass}>
                <Stack direction="column" mt={2} gap={2}>
                    <div>
                        <Typography fontSize={14} >New password</Typography>
                        <Paper className={classes.paperBase}>
                            <Box className={classes.inpIcon}>
                                <CiLock size={20} color="#9fa1a3" />
                            </Box>
                            <InputBase
                                autoFocus
                                className={classes.input}
                                value={pass1}
                                type={showPass1 ? "text" : "password"}
                                onChange={(e) => setPass1(e.target.value?.trim())}
                            />
                            <Box className={classes.eye}>
                                <IconButton onClick={() => setShowPass1(!showPass1)}>
                                    {showPass1 ? <BsEyeSlash size={18} /> : <BsEyeFill size={18} />}
                                </IconButton>
                            </Box>
                        </Paper>
                    </div>

                    <div>
                        <Typography fontSize={14} >Confirm password</Typography>
                        <Paper className={classes.paperBase}>
                            <Box className={classes.inpIcon}>
                                <CiLock size={20} color="#9fa1a3" />
                            </Box>
                            <InputBase
                                className={classes.input}
                                value={pass2}
                                type={showPass2 ? "text" : "password"}
                                onChange={(e) => setPass2(e.target.value?.trim())}
                            />
                            <Box className={classes.eye}>
                                <IconButton onClick={() => setShowPass2(!showPass2)}>
                                    {showPass2 ? <BsEyeSlash size={18} /> : <BsEyeFill size={18} />}
                                </IconButton>
                            </Box>
                        </Paper>
                        <FormHelperText error={error} >{error}</FormHelperText>
                    </div>

                    <Button
                        type='submit'
                        disabled={btnLoading || !pass1 || !pass2}
                        variant="contained"
                    >
                        {btnLoading ?
                            <Stack direction={"row"}>
                                <Typography>please wait...</Typography>
                            </Stack>
                            : <span style={{ fontWeight: "bold", color: 'white' }}>
                                Reset password
                            </span>}
                    </Button>

                    <Button
                        variant="text"
                        sx={{ textTransform: "none" }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Stack>
            </form>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        padding: "60px 40px",
        backgroundColor: "#fff",
    },
    paperBase: {
        display: "flex",
        width: "100%",
        borderRadius: 8,
        border: "1px solid #d1d4d7"
    },
    inpIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 50,
        borderRight: "1px solid #d1d4d7",
        backgroundColor: "#E4E5E6"
    },
    input: {
        flex: 1,
        padding: "2px 10px",
        backgroundColor: "#E8F0FE"
    },
    eye: {
        paddingRight: 10,
        backgroundColor: "#E8F0FE",
    },
}))