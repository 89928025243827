export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const validatePassword = (pass) => {
    const PassErrorMessages = {
        number: "Password should have at least 1 numeric character",
        alpha: "Password should have at least 1 alphabatic character",
        special: "Password should have at least 1 special character",
        length: "Length of the password should be between 6 to 16",
        invalid: "Invalid character!! These are the valid characters (a-zA-Z0-9!@#$%^&*)"
    }

    let result

    let validateRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{6,}$/;

    let test = {
        number: /^(?=.*[0-9])/,
        alpha: /^(?=.*[a-zA-Z])/,
        special: /^(?=.*[!@#$%^&*])/,
        length: /^.{6,16}$/,
        invalid: /^[a-zA-Z0-9!@#$%^&*]*$/
    }

    //@ts-ignore
    Object.keys(test).forEach((key) => {
        const regEx = new RegExp(test[key]);
        //@ts-ignore
        if (!regEx.test(pass)) {
            if (PassErrorMessages[key])
                result.push(PassErrorMessages[key])
        }
    })

    return result;
};