import { Avatar, Box, TextField } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { stringToColor } from "../utils/utilities"

export const Pressable = ({ onPress, children, ...props }) => {
    const classes = useStyles()
    return (
        <Box
            onClick={onPress}
            className={classes.pressable}
            {...props}
        >
            {children}
        </Box>
    )
}


export const NameAvatar = ({ name, size }) => {
    return (
        <Avatar
            sx={{
                width: size, height: size,
                fontSize: size / 2.33,
                bgcolor: stringToColor(String(name))
            }}
        >
            {`${String(name).split(' ')[0][0]}${String(name).split(' ')[1][0]}`.toUpperCase()}
        </Avatar>
    )
}

export const InputBox = (props) => {
    const classes = useStyles();
    return (
        <TextField
            // fullWidth
            size="small"
            // width={props.width ?? 400}
            className={classes.input}
            inputProps={{
            }}
            InputProps={{
                style: { borderRadius: 8, fontSize: 16, padding: "2px 4px" }
            }}
            // error
            {...props}
        />
    )
}

const useStyles = makeStyles(theme => ({
    pressable: {
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-evenly",
    },
    input: {
        width: "400px !important",
        fontSize: 10
    }
}))