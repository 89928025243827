import {
  Box,
  Stack,
  Typography,
  Table, TableBody,
  TableContainer, TableHead, TableRow,
  Pagination, styled,
  Paper,
  Skeleton,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import React, { useContext, useEffect, useState } from "react";
import Container from "../components/container";
import Drawer from "../components/drawer";
import { useNavigate } from "react-router-dom";
import { Context as AppContext } from "../context/AppContext";
import moment from "moment";
import BreadcrumbTrail from "../components/BreadcrumbsTrail";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useDispatch, useSelector } from "../store";
import { getDevices } from "../slices/batteriesSlice";

const getStatusColor = (status) => {
  switch (status) {
    case "offline":
      return "#f8cb00"

    case "discharging":
      return "#f77268"

    default:
      return "#4dbd74"
  }
}

const getStatusText = (status) => {
  switch (status) {
    case "offline":
      return "Offline"

    case "standby":
      return "Operational"

    case "charging":
      return "Charging"

    case "discharging":
      return "Discharging"

    default:
      "Offline"
  }
}

const Devices = () => {
  const [containerClass, setContainerClass] = useState("");
  const [drawerContainerClass, setDrawerContainerClass] = useState("");
  const classes = useStyles();
  const { state, show } = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [contentLoading, setContentLoading] = useState(true)
  const [devices, setDevices] = useState([])
  const dispatch = useDispatch();
  const rdx = useSelector((state) => state.batteries);
  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      setContainerClass(null);
      setDrawerContainerClass(null);
    } else {
      setContainerClass(classes.containeropen);
      setDrawerContainerClass(classes.drawercontaineropen);
    }
  }, [show]);

  useEffect(() => {
    if (rdx.records && rdx.records.length == 0) {
      setContentLoading(rdx.loading);
      setLoading(rdx.loading);
    } else if (rdx.records.length !== 0 && rdx.loading) {
      setContentLoading(rdx.loading);
    } else {
      setContentLoading(false);
      setLoading(false);
    }
  }, [rdx.loading])


  useEffect(() => {
    const getBatteriesRdx = async () => {
      try {
        dispatch(getDevices(state.token));
      } catch (e) {
        console.log("Customer list API failed to get data", e)
      }
    }
    getBatteriesRdx()
  }, []);

  useEffect(() => {
    if (rdx.count) {
      setDevices(rdx.records.slice(perPage * (page - 1), perPage * page))
    } else {
      setDevices([])
      setPage(1)
    }
  }, [page, perPage, rdx.records])

  const handleNav = (item) => {
    if (item.type === 2) {
      navigate(
        `/home/batteries/inverter/${item.deviceId}`
      );
    } else {
      navigate(
        `/home/batteries/battery/${item.deviceId}`
      );
    }
  }


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      fontWeight: 500
    },
  }));

  StyledTableCell.defaultProps = {
    sx: { textWrap: "nowrap" }
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: "#eafafc",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(+event.target.value);
    setPage(1);
  };

  return (
    <>
      <Stack direction="row">
        <Box
          height="100%"
          className={clsx(classes.drawercontainer, drawerContainerClass)}
        >
          <Drawer show={show} location="batteries" />
        </Box>
        <Box className={clsx(classes.container, containerClass)}>
          <BreadcrumbTrail sx={{ my: 2 }} />
          <Container
            loading={contentLoading}
            title="Devices"
            style={{ root: { margin: 40, marginTop: 0 } }}
          >
            <Box className={classes.customers}>
              <Box className={classes.agentTable} style={{ width: "100%" }}>
                <TableContainer component={Paper} className="tableContainer">
                  <Table >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>
                          Device ID
                        </StyledTableCell>
                        <StyledTableCell align="left" >Last Activity</StyledTableCell>
                        <StyledTableCell>Location</StyledTableCell>
                        <StyledTableCell align="center">Capacity</StyledTableCell>
                        <StyledTableCell align="center">
                          Discharge Rate
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Efficiency (%)
                        </StyledTableCell>
                        <StyledTableCell align="center" >
                          Total Import <br />(KWh)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Total Export <br />(KWh)
                        </StyledTableCell>
                        <StyledTableCell align="center" >
                          State of <br />Charge (%)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Status
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.customerTableBody}>
                      {loading ?
                        <>
                          {Array.from({ length: perPage }, () => Math.floor(Math.random() * 40)).map((x, index) => {
                            return (
                              <StyledTableRow key={index}>
                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                {/* <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell> */}
                              </StyledTableRow>
                            )
                          })}
                        </>
                        :
                        devices.length ?
                          devices.map((item, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="left">
                                <Typography>
                                  <a
                                    variant="text"
                                    onClick={() => handleNav(item)}
                                    className="clickable"
                                  >  {item.name ?? item.deviceId}
                                  </a>
                                </Typography>
                              </StyledTableCell>

                              <StyledTableCell >
                                <Typography>
                                  {moment(item.lastActivityTime).format(
                                    "MM/DD/YYYY hh:mm:ss"
                                  )}
                                </Typography>
                              </StyledTableCell>

                              <StyledTableCell >
                                <Typography>{(item.address?.trim()?.length ? item.address : "-") ?? "-"}</Typography>
                              </StyledTableCell>

                              <StyledTableCell align="center" >
                                <Typography>{item.capacity ?? "-"}</Typography>
                              </StyledTableCell>

                              <StyledTableCell align="center" >
                                <Typography>{item.dischargeRate ?? "-"}</Typography>
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <Typography>{item.efficiency ? item.efficiency * 100 : "-"}</Typography>
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <Typography>{item.totalImportKWh ? Number(item.totalImportKWh).toFixed(2) : "-"}</Typography>
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <Typography>{item.totalExportKWh ? Number(item.totalExportKWh).toFixed(2) : "-"}</Typography>
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <Typography>{(item.soC ? item.soC * 100 : "-")}</Typography>
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <Box
                                  className={classes.status}
                                  style={{
                                    backgroundColor: getStatusColor(item.status)
                                    // item.status === "offline" ? "#f8cb00" : item.status === "discharging" ? "#f77268" : "#4dbd74"
                                  }}

                                >
                                  <Typography color="#fff" fontWeight={"bold"} fontSize={10}>
                                    {getStatusText(item.status)}
                                    {/* {item.status === "offline" ? "Offline" : item.status === "standby" ? "Operational" : item.status === "charging" ? "Charging" : "Discharging"} */}
                                  </Typography>
                                </Box>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                          : (
                            <StyledTableRow>
                              {["", "No records", "", "", "", "", "", "", ""].map(content => <StyledTableCell >{content}</StyledTableCell>)}
                            </StyledTableRow>
                          )
                      }
                    </TableBody>
                  </Table>
                  <Divider />
                  <Box
                    className={classes.pagination}>
                    <Typography className={classes.showingCount}>Showing Devices ({devices?.length}/{rdx.count})</Typography>
                    <Box className={classes.flexBox}>
                      <Pagination
                        count={Math.ceil(rdx.count / perPage)}
                        className={classes.pageButtons}
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                        page={page}
                        disabled={contentLoading}
                        onChange={handleChangePage}
                      />

                      <Box className={classes.flexBox}>
                        <Typography fontSize={14} >Row per page: </Typography>
                        <Select sx={{ fontSize: 14 }} size="small" value={perPage} disabled={loading || contentLoading} onChange={handleChangeRowsPerPage}>
                          {[5, 10, 25, 50].map((val) => (
                            <MenuItem sx={{ fontSize: 14 }} value={val}>{val}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                  </Box>

                </TableContainer>
              </Box>
            </Box>
          </Container>
        </Box>
      </Stack>
    </>
  );
};

export default Devices;

const useStyles = makeStyles(theme => ({
  container: {
    width: "80%",
    // padding: 40,
    paddingTop: 60,
    transition: "width 0.3s ease-out",
    // zIndex: -1,
  },
  containeropen: {
    width: "100%",
  },
  drawercontainer: {
    width: "20%",
    transition: "width 0.1s ease-out",
  },
  drawercontaineropen: {
    width: "0%",
    zIndex: 100,
  },
  status: {
    display: "flex",
    justifyContent: "center",
    padding: "2px 5px",
    borderRadius: 10
  },
  customers: {
  },
  agentTable: {
    "& .MuiTableContainer-root": {
      // borderRadius: "0px !important",
    },
    "& .MuiTableCell-head": {
      // display: "flex",
      // gap: 5,
      fontWeight: "normal",
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: "white",
      fontSize: 14,
      padding: "2px 5px"
    },
    "& .clickable": {
      textDecoration: "none",
      textTransform: "uppercase",
      padding: 0,
      fontSize: 14,
      color: theme.palette.primary.main,
      cursor: "pointer"
    },
    "& .MuiTypography-root": {
      fontSize: 14,
    },
    "& .tableContainer": {
      // border: "1px solid #FF7676",
      // borderRadius: 20
    },
    "& .planProvider": {
      fontWeight: 700
    },
    "& .cellContainer": {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    },
  },
  customerTableBody: {
    "& .MuiTableCell-body": {
      fontSize: 14
    },
    "& .MuiTableCell-root": {
      padding: "12px 7px"
    }
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    height: "100%"
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 5,
    height: "100%",
    marginBottom: 4,
    padding: "0px 10px",
    gap: "20px !important",
    justifyContent: "space-between",
    "&.MuiSelect-select": {
      padding: "5px 14px !important"
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "normal",
      alignItems: "flex-start",
      gap: "10px !important"
    }
  },
  showingCount: {
    fontWeight: 'bold',
    fontSize: "14px !important"
  },
  pageButtons: {
    margin: "8px 0px",
    fontSize: 12,
    [theme.breakpoints.down("md")]: {
      margin: "2px !important"
    }
  }
}));
