
export class Constants {
    static project_number = "618183720309"
    static project_id = "chargesync-421810"
    static firebaseConfig = {
        apiKey: "AIzaSyDlHEvpBsS6nBkhG9T9fWDOFOGJ4f7SWTA",
        authDomain: `${this.project_id}.firebaseapp.com`,
        projectId: this.project_id,
        storageBucket: `${this.project_id}.appspot.com`,
        messagingSenderId: this.project_number,
        appId: "1:618183720309:android:538d5b97c7e233d44b673c"
    };
}