import { Box, Button, FormHelperText, Grid, Stack, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { Context as AppContext } from "../context/AppContext";
import { useContext, useEffect, useState } from "react";
import BreadcrumbTrail from "../components/BreadcrumbsTrail";
import clsx from "clsx";
import Drawer from "../components/drawer";
import Container from "../components/container";
import { InputBox } from "../components/muiComponents";
import { validateEmail, validatePassword } from "../utils/utilities";
import { RegisterCustomer } from "../Api/homeApi";
import { useNavigate } from "react-router-dom";

export default () => {
    const classes = useStyles();
    const [containerClass, setContainerClass] = useState("");
    const [drawerContainerClass, setDrawerContainerClass] = useState("");
    const { show, state } = useContext(AppContext)
    const [error, setError] = useState([])
    const [loading, setLoading] = useState(false)
    const [userInfo, setUserInfo] = useState({
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNumber: "",
        Password: "",
        Confirm: "",
    })
    const [isErrors, setIsErrors] = useState({
        isFirstName: false,
        isLastName: false,
        isEmail: false,
        isPhoneNumber: false,
        isPassword: false,
        isConfirm: false,
    })


    const navigate = useNavigate();

    const onChange = (key, value) => {
        if (key == "PhoneNumber") {
            const numericValue = value.replace(/[^\d]/g, '');

            // Limit input to 10 digits
            if (numericValue.length <= 11) {
                // Format phone number into UK format (e.g., 01234 567890)
                let formattedPhoneNumber = '';
                for (let i = 0; i < numericValue.length; i++) {
                    if (i === 5) {
                        formattedPhoneNumber += ' ';
                    }
                    formattedPhoneNumber += numericValue.charAt(i);
                }
                setUserInfo((prevState) => ({
                    ...prevState,
                    [key]: formattedPhoneNumber
                }));
            }
        } else {
            setUserInfo((prevState) => ({
                ...prevState,
                [key]: value
            }));
        }
    }

    const onSubmit = async () => {
        let _isErrors = {
            isConfirm: false,
            isEmail: false,
            isFirstName: false,
            isLastName: false,
            isPassword: false,
            isPhoneNumber: false
        }
        if (!userInfo?.Email) {
            _isErrors.isEmail = true;
        }
        if (!userInfo?.FirstName) {
            _isErrors.isFirstName = true;
        }

        if (!userInfo?.LastName) {
            _isErrors.isLastName = true;
        }

        if (!userInfo?.Password) {
            _isErrors.isPassword = true;
        }

        if (!userInfo?.Confirm) {
            _isErrors.isConfirm = true;
        }

        if (!userInfo?.PhoneNumber) {
            _isErrors.isPhoneNumber = true;
        }

        if (!userInfo?.Email || !userInfo.FirstName || !userInfo.LastName || !userInfo.Password || !userInfo.Confirm
            || !userInfo.PhoneNumber
        ) {
            setError(
                !userInfo?.FirstName ? "FirstName is required" :
                    !userInfo.LastName ? "LastName is required" :
                        !userInfo?.Email ? "Email is required" :
                            !userInfo.Password ? "Password is required" :
                                !userInfo.PhoneNumber ? "Phone Number is required" :
                                    !userInfo.Confirm ? "Confirm Password is required" :
                                        "Confirm Password is required");

            setIsErrors(_isErrors);
        } else if (typeof userInfo.Email === "string" && !validateEmail(userInfo.Email)) {
            setError("Invalid email")
        } else if (typeof userInfo.Password === "string" && validatePassword(userInfo.Password)?.length > 0) {
            setError(validatePassword(userInfo.Password))
        } else {
            setLoading(true);
            setError("")
            setIsErrors({
                isConfirm: false,
                isLastName: false,
                isPassword: false,
                isEmail: false,
                isFirstName: false
            })
        }

        console.log("userInfo submitted ==>", userInfo)
        await RegisterCustomer(userInfo)
    }

    useEffect(() => {
        if (show) {
            setContainerClass(null);
            setDrawerContainerClass(null);
        } else {
            setContainerClass(classes.containeropen);
            setDrawerContainerClass(classes.drawercontaineropen);
        }
    }, [show]);

    return (
        <>
            <Stack direction="row">
                <Box
                    height="100%"
                    className={clsx(classes.drawercontainer, drawerContainerClass)}
                >
                    <Drawer show={show} location="customers" />
                </Box>

                <Box className={clsx(classes.container, containerClass)}>
                    <BreadcrumbTrail sx={{ my: 2 }} />
                    <Box className={classes.main}>
                        <Container title={`Register new customer`} style={{ root: { width: 500 } }} >
                            <form onSubmit={(e) => {
                                e.preventDefault()
                                onSubmit()
                            }} >
                                <Grid container rowGap={3} className={classes.formGrid} >
                                    <Grid item xs={12}>
                                        <InputBox
                                            error={isErrors.isFirstName}
                                            value={userInfo.FirstName}
                                            onChange={(e) => onChange("FirstName", e.target.value)}
                                            label="First Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBox
                                            error={isErrors.isLastName}
                                            value={userInfo.LastName}
                                            onChange={(e) => onChange("LastName", e.target.value)}
                                            label="Last Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBox
                                            error={isErrors.isEmail}
                                            value={userInfo.Email}
                                            onChange={(e) => onChange("Email", e.target.value)}
                                            label="Email"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBox
                                            error={isErrors.isPhoneNumber}
                                            value={userInfo.PhoneNumber}
                                            onChange={(e) => onChange("PhoneNumber", e.target.value)}
                                            label="Phone Number"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBox
                                            error={isErrors.isPassword}
                                            value={userInfo.Password}
                                            onChange={(e) => onChange("Password", e.target.value)}
                                            label="Password"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputBox
                                            error={isErrors.isConfirm}
                                            value={userInfo.Confirm}
                                            onChange={(e) => onChange("Confirm", e.target.value)}
                                            label="Confirm Password"
                                        />
                                    </Grid>
                                    {Array.isArray(error) ?
                                        error.map((e, index) => (
                                            <FormHelperText key={index} style={{ color: "red" }} error={e} visible={true} >
                                                {e}
                                            </FormHelperText>
                                        )) :
                                        <FormHelperText error={error} type="error" visible={typeof error === "string" && error.length > 0} >
                                            {error}
                                        </FormHelperText>
                                    }
                                    <Grid item xs={12}>
                                        <Box className={classes.flexBox} >
                                            <Button
                                                className={classes.btnStyle}
                                                type="button"
                                                variant="outlined"
                                                disabled={loading}
                                                onClick={() => navigate("/home/customers")}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className={classes.btnStyle}
                                                type="submit"
                                                variant="contained"
                                                disabled={loading}
                                            >
                                                Register
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </Container>
                    </Box>
                </Box>
            </Stack>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 60,
        width: "80%",
        transition: "width 0.3s ease-out",
        padding: 0
    },
    main: {
        display: "flex",
        flexDirection: "column",
        gap: 30,
        margin: 40,
        marginTop: 0
    },
    containeropen: {
        width: "100%",
    },
    drawercontainer: {
        width: "20%",
        transition: "width 0.1s ease-out",
    },
    drawercontaineropen: {
        width: "0%",
        zIndex: "100",
    },
    formGrid: {
        padding: "10px 30px"
    },
    flexBox: {
        display: "flex",
        gap: 30,
        alignItems: "center",
    },
    btnStyle: {
        // borderRadius: "50%"
    }
}))