import { firestore } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore"

export const fetchUnreadNotifications = async (userId) => {
    try {
        const userDocRef = doc(firestore, 'notifications', 'users', userId, 'new');
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            return data?.list;
        } else {
            return []
        }
    } catch (error) {
        console.error('Error fetching notifications:', error);
    }
};

export const addNotification = async (userId, notification) => {
    try {
        const userDocRef = doc(firestore, 'notifications', 'users', userId, 'new');
        const docSnap = await getDoc(userDocRef);

        if (!docSnap.exists()) {
            // Create the document with an empty list if it doesn't exist
            await setDoc(userDocRef, { list: [notification] });
            console.log('Created new unread document with notification.');
        } else {
            const data = docSnap.data();
            const notifications = data?.list || [];

            notifications.push(notification);

            await updateDoc(userDocRef, { list: notifications });
            console.log('Added notification to existing unread document.');
        }
    } catch (error) {
        console.error('Error adding notification:', error);
    }
};